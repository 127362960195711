import { useState } from "react";
import { useDispatch } from "react-redux";
import { connectWalletAction } from "../../actions/wallet";

import WalletButton from "./wallet-button/WalletButton";
import WalletModal from "./wallet-modal/WalletModal";

const Wallet = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <WalletButton
        onConnectClick={() => {
          if (["optimism", "arbitrum"].includes(window.__UXD__.config.chain)) {
            dispatch(connectWalletAction("metamask"));
            return;
          }
          setModalOpen(true);
        }}
      />
      {modalOpen ? <WalletModal setOpen={setModalOpen} /> : null}
    </>
  );
};

export default Wallet;
