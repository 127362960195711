import { useDispatch } from "react-redux";

import { disconnectWalletAction } from "../../../actions/wallet";
import { useSelector } from "../../../hooks/hooks";
import Button from "../../common/button/Button";

import "./WalletButton.scss";

type Props = {
  onConnectClick: () => void;
};

const WalletButton = ({ onConnectClick }: Props) => {
  const walletState = useSelector((state) => state.wallet);
  const walletAdapterName =
    walletState !== null && typeof walletState === "object"
      ? walletState.name
      : walletState;

  const dispatch = useDispatch();
  const disabled =
    ["optimism", "arbitrum"].includes(window.__UXD__.config.chain) &&
    !window.__UXD__.optimism.provider;

  if (!walletAdapterName) {
    return (
      <Button
        type="button"
        onClick={() => onConnectClick()}
        className="WalletButton WalletButton-connect"
        disabled={disabled}
      >
        Connect
      </Button>
    );
  }

  return (
    <Button
      type="button"
      className="WalletButton WalletButton-disconnect"
      onClick={() => dispatch(disconnectWalletAction(walletAdapterName))}
    >
      Disconnect
    </Button>
  );
};

export default WalletButton;
