import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import {
  ARBITRUM_GOVERNANCE_USDC_TREASURY,
  ETHEREUM_GOVERNANCE_USDC_TREASURY,
} from "../components/dashboard/insurance-fund-positions/InsuranceFundPositions";
import { PublicKey } from "@solana/web3.js";
import { AccountLayout, u64 } from "@solana/spl-token";
import { nativeToUi } from "../utils/amount";

const getEthereumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // USDC
    // https://etherscan.io/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "homestead",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ETHEREUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getArbitrumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // Bridged USDC
    // https://arbiscan.io/token/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "arbitrum",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ARBITRUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getMultipleTokenAccountBalances = async (
  accounts: { pubkey: PublicKey; decimals: number }[]
): Promise<(number | null)[]> => {
  const accountsInfo =
    await window.__UXD__.solana.connection.getMultipleAccountsInfo(
      accounts.map(({ pubkey }) => pubkey)
    );

  return accountsInfo.map((accountInfo, i) => {
    if (!accountInfo) return null;

    const nativeAmount = u64.fromBuffer(
      AccountLayout.decode(accountInfo.data).amount
    );

    return nativeToUi(nativeAmount, accounts[i].decimals);
  });
};

export default function useInsuranceFundPositions() {
  const USDCMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("USDC").mint.toBase58(),
    []
  );
  const UXDMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("UXD").mint.toBase58(),
    []
  );

  const [positions, setPositions] = useState<{
    solana: {
      USDCVaultHolding: number | null;
      USDCHotWalletATA: number | null;
      UXDVaultHolding: number | null;
      UXDHotWalletATA: number | null;
      UXDProgramAuthorityATA: number | null;
    };
    ethereum: {
      USDCVaultHolding: number | null;
    };
    arbitrum: {
      USDCVaultHolding: number | null;
    };
  } | null>(null);

  useEffect(() => {
    const fetchInsuranceFundPositions = async () => {
      // Launch all requests in parallel
      const ethereumUSDCDepositPromise = getEthereumUSDCDeposit();
      const arbitrumUSDCDepositPromise = getArbitrumUSDCDeposit();

      const accountsBalancesPromise = getMultipleTokenAccountBalances([
        {
          // UXDProgramAuthorityATA
          pubkey: new PublicKey("REFVReVTe9cYMgxpZQ3NJ7LWRLPjPUFoQPZUcA3MezQ"),
          decimals: 6,
        },
        {
          // UXDVaultHolding
          pubkey: new PublicKey("BBUcWRZKTyByVzm9YpJsQxeWzecZbhUbr5EpW4VS8oW6"),
          decimals: 6,
        },
        {
          // UXDHotWalletATA
          pubkey: new PublicKey("GwSLedXAznA4NfvnSALGsuHG2hgope3vUYJZCXog1vu4"),
          decimals: 6,
        },
        {
          // USDCHotWalletATA
          pubkey: new PublicKey("CzztF4jyKfRQU6J4eCXhZEpMsg6iZAKtQjgQEoKrKNvZ"),
          decimals: 6,
        },
        {
          // USDCVaultHolding
          pubkey: new PublicKey("9DG795GEYQ7byWz9HAbWkw7Q7W39P3hS614VDRobiAHh"),
          decimals: 6,
        },
        {
          // Other DAO account
          pubkey: new PublicKey("FWgCYusFKJfsaN4kaiHRGfNpRTb1q7Zms8pCmPv1XCVo"),
          decimals: 6,
        },
        {
          // Other DAO account 2
          pubkey: new PublicKey("9en2QCzV4B6P9vwMkjEk11iMzW1dCrYdCpev2dXemHjq"),
          decimals: 6,
        },
      ]);

      // Wait it all
      const [
        ethereumUSDCDeposit,
        arbitrumUSDCDeposit,
        [
          UXDProgramAuthorityATABalance,
          UXDVaultHoldingBalance,
          UXDHotWalletATABalance,
          USDCHotWalletATABalance,
          USDCVaultHoldingBalance,
          OtherDaoAccountBalance,
          OtherDaoAccount2Balance,
        ],
      ] = await Promise.all([
        ethereumUSDCDepositPromise,
        arbitrumUSDCDepositPromise,
        accountsBalancesPromise,
      ]);

      setPositions({
        solana: {
          UXDVaultHolding: UXDVaultHoldingBalance ?? 0,
          UXDHotWalletATA: UXDHotWalletATABalance ?? 0,
          UXDProgramAuthorityATA: UXDProgramAuthorityATABalance ?? 0,
          USDCHotWalletATA: USDCHotWalletATABalance ?? 0,
          USDCVaultHolding:
            (USDCVaultHoldingBalance ?? 0) +
            (OtherDaoAccountBalance ?? 0) +
            (OtherDaoAccount2Balance ?? 0),
        },
        ethereum: {
          USDCVaultHolding: ethereumUSDCDeposit,
        },
        arbitrum: {
          USDCVaultHolding: arbitrumUSDCDeposit,
        },
      });
    };

    fetchInsuranceFundPositions();
  }, [USDCMintAddress, UXDMintAddress]);

  return positions;
}
